main {
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    font-family: Verdana, sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

.app-wide {
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    font-family: Verdana, sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

.columns {
    border: solid 1px blue;
    display: flex;
}

.columns > div {
    border: solid 1 px #ccc;
    flex: 1;
}

.columns > div:nth-of-type(2) {
    flex: 2;
}

.form-table {
    display: flex
}

.form-container input {
    text-align: left;
    border-radius: 5px;
}

.rectangle-box {
    display: inline-flex;
    text-align: center;
    align-self: center;
    border: 2px solid rgb(32, 8, 8);
    fill: black;
    padding: 10px;
    width: 300px;
    height: 40px;
    background-color: black;
    color: rgb(190, 172, 67);
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.rectangle-box-p {
    align-self: center;
    text-align:center;
}

.body-paragraph {
    align-self: center;
    text-align: center;
    width: 100%;
}

.button-style {
    background-color: hsl(0, 0%, 0%);
    color: #ffd700;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    cursor: pointer
};